import React, { Component } from 'react';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      width: props.width,
      height: props.height,
      margin: props.margin
    };
  }

  componentDidMount() {
    this.loadD3();
  }

  componentDidUpdate() {
    this.loadD3();
  }

  loadD3() {
      if(!window.d3) {
        const script = document.createElement('script');
        script.src = 'https://d3js.org/d3.v7.min.js';
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
          this.renderChart();
        };
      } else {
          this.renderChart();
      }
  }

  renderChart() {
    const { data, width, height, margin } = this.state;
    const svg = d3.select(this.refs.svg);
    console.log("DATA", data);
    if(!data) {
        return null;
    }

    // Set up the scales
    const xScale = d3.scaleBand()
      .domain(data.map(d => d.category))
      .range([margin.left, width - margin.right])
      .padding(0.2);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([height - margin.bottom, margin.top]);

    // Set up the axes
    const xAxis = d3.axisBottom(xScale);
    const yAxis = d3.axisLeft(yScale);

    // Add the axes to the SVG
    svg.append('g')
      .attr('transform', `translate(0, ${height - margin.bottom})`)
      .call(xAxis);

    svg.append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis);

    // Add the bars to the SVG
    const bars = svg.selectAll('rect')
      .data(data);

    bars.enter()
      .append('rect')
      .attr('x', d => xScale(d.category))
      .attr('y', d => height - margin.bottom - yScale(d.value))
      .style("fill", "#ff0000")
      .attr('width', xScale.bandwidth())
      .attr('height', d => yScale(d.value));

    bars.transition()
      .attr('x', d => xScale(d.category))
      .attr('y', d => height - margin.bottom - yScale(d.value))
      .attr('width', xScale.bandwidth())
      .attr('height', d => yScale(d.value));
  }

  render() {
    return (
        <svg ref="svg" width={this.state.width} height={this.state.height}></svg>
    );
  }
}

export default BarChart;