import React, { Component } from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFolder, faFolderOpen, faFile } from '@fortawesome/free-solid-svg-icons';

class TreeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: this.props.data
        };
    }

    toggleExpandCollapse = (node) => {
        console.log("Expand", node.expanded)
        node.expanded = !node.expanded;
        this.setState({ treeData: this.state.treeData });
    };

    handleCheckboxChange = (node, event) => {
        node.checked = event.target.checked;
        this.setState({ treeData: this.state.treeData });
    };

    renderTreeNodes(nodes) {
        const extensionIcons = {
            "xls": "file-excel-o",
            "tml": "file-code-o",
            "csv": "file-excel-o",
            "txt": "file-text-o",
            "pdf": "file-pdf-o",
            "docx": "file-word-o",
        }

        return nodes.map((node, index) => (
            <li key={index} style={this.styles.li}>
                { node.children.length ?
                    !node.expanded ?
                        <i onClick={() => this.toggleExpandCollapse(node)} style={this.styles.expandCollapseIcon} className="left-arrow fa fa-chevron-right" />
                        :
                        <i onClick={() => this.toggleExpandCollapse(node)} style={this.styles.expandCollapseIcon} className="left-arrow fa fa fa-chevron-down" />

                    :
                    null
                }
                { node.children.length == 0 ?
                    <input
                        type="checkbox"
                        value={node.checked ? false : true}
                        onChange={(event) => this.handleCheckboxChange(node, event)}
                    />
                    :
                    null
                }
                { node.children.length ?
                    //<i style={this.styles.folderIcon} className="fa fa-folder-o" />
                    null
                    :
                    <i style={this.styles.fileIcon} className={"fa fa-" + (extensionIcons[node.text.substr(-3)] ? extensionIcons[node.text.substr(-3)] : 'file-o') } />
                }
                <span style={{fontSize:18}}>
                    {node.text}
                </span>
                { node.children.length ?
                    <i onClick={this.props.handleAddUpload.bind(this, node.text)}  style={this.styles.addUploadIcon} className={"fa fa-plus"} />
                    :
                    null
                }
                { node.status ?
                    <span style={{position:"absolute", left:400,}}>{node.status}</span>
                    :
                    null
                }
                { node.uploaded ?
                    <span style={{position:"absolute", left:540,}}>{node.uploaded}</span>
                    :
                    null
                }

                {node.expanded && node.children ?
                    <ul>
                        {this.renderTreeNodes(node.children)}
                    </ul>
                    :
                    null
                }
            </li>
        ))
    }

    render() {
        return (
            <div style={this.styles.treeContainer}>
                <ul style={this.styles.ul}>
                    {this.renderTreeNodes(this.state.treeData)}
                </ul>
            </div>
        );
    }

    styles = {
        ul: {
            listStyleType: "none",
            marginBottom: 18,
        },
        li: {
            listStyleType: "none",
            marginBottom: 8,
            position: "relative",

        },
        folderIcon: {
            color:"#2992F5",
            marginRight: 5,
        },
        fileIcon: {
            fontSize: 20,
            color:"#53CBCC",
            marginRight: 5,
        },
        addUploadIcon: {
            fontSize: 20,
            color:"blue",
            marginRight: 5,
            marginLeft: 5,
        },
        expandCollapseIcon: {
            cursor:"pointer",
            marginRight: 5,
            marginBottom: 5,

        }

    }


}

export default TreeList;
