import React from 'react';

import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import Utils from '../modules/Utils';
import StylesPlatform from '../modules/StylesPlatform';

import Icon from './Icon';

import Div from '../components/Div';

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getInputColors() {
        const pr = this.props;
        switch(pr.color) {
            case "purple":
                return {
                    backgroundColor: Colors.plumLight,
                    color: "white",
                    border: "none",
                };
            case "indigoLight":
                return {
                    backgroundColor: Colors.indigoLight,
                    color: "white",
                    border: "none",
                };
            case "indigo":
                return {
                    backgroundColor: Colors.indigo,
                    color: "white",
                    border: "none",
                };
            default:
                null;
        }
    }

    getInputTypeStyle() {
        const pr = this.props;
        switch(pr.inputType) {
            case "search":
                return null;
            case "icon":
                return null;
            case "placeholderLabel":
                return {
                    background: "none",
                    padding: 0,
                    margin: 0,
                    borderRadius: 0,
                    border: "none",
                    color: "white",
                };
            default:
                return null;
        }
    }

    onKeyDown(e) {
        const pr = this.props;
        if(pr.onEnter) {
            if(e.key === 'Enter') {
                pr.onEnter();
                return;
            }
        }
    }

    render() {
        const pr = this.props;
        const sty = this.styles;

        const errorStyle = pr.isError ? sty.errorStyle : null;

        let inputColors = this.getInputColors();
        let inputTypeStyle = this.getInputTypeStyle();
        const onChange = pr.onChange ? pr.onChange.bind(this) : null;
        const maxLength = parseInt(Utils.get(pr, "maxLength")) > 0 ? parseInt(Utils.get(pr, "maxLength")) : null;

        if(pr.inputType == "search") {
            return (
                <Div style={{...sty.searchInputContainer, ...pr.searchContainerStyle}} >
                    <Icon icon="search" size={18} style={sty.iconStyle} />
                    <input
                        {...pr}
                        style={{...sty.input, ...sty.searchInput, ...inputColors, ...pr.style}}
                        className={`inputElement ${Utils.get(pr, "className", "")}`}
                        placeholder={pr.placeholder ? pr.placeholder : "Search"}
                        readOnly={pr.readOnly}
                        maxLength={maxLength}
                    />
                </Div>
            );
        } else if(pr.inputType == "icon") {
            return (
                <Div style={{...sty.searchInputContainer, ...pr.iconContainerStyle}} >
                    <Icon icon={pr.inputIcon} size={pr.inputIconSize ? pr.inputIconSize : 18} style={sty.iconStyle} />
                    <input
                        {...pr}
                        style={{...sty.input, ...sty.searchInput, ...inputColors, ...pr.style}}
                        className={`inputElement ${Utils.get(pr, "className", "")}`}
                        placeholder={pr.placeholder ? pr.placeholder : ""}
                        maxLength={maxLength}
                        readOnly={pr.readOnly}
                    />
                </Div>
            );
        } else if(pr.inputType == "text") {
            return (
                <Div style={{...sty.searchInputContainer, ...pr.searchContainerStyle}} >
                    {pr.icon ? <Icon icon={pr.icon} size={18} style={sty.iconStyle} /> : null}
                    {pr.inputText ? <Div style={sty.iconStyle}>{pr.inputText}</Div> : null}
                    <input
                        style={{...sty.input, ...sty.textInput, ...inputColors, ...pr.style}}
                        className={`inputElement ${Utils.get(pr, "className", "")}`}
                        placeholder={Utils.get(pr, "placeholder", "")}
                        type={Utils.get(pr, "type", "")}
                        value={pr.value}
                        onChange={onChange}
                        readOnly={pr.readOnly}
                        maxLength={maxLength}
                    />
                </Div>
            );
        } else if(pr.inputType == "iconButton") {
            return (
                <Div style={{...sty.iconButtonInputContainer, ...pr.iconButtonInputContainerStyle}} >
                    <input
                        style={{...sty.input, ...sty.iconButtonInput, ...inputColors, ...pr.style}}
                        className={`inputElement ${Utils.get(pr, "className", "")}`}
                        placeholder={pr.placeholder ? pr.placeholder : "Search"}
                        type={Utils.get(pr, "type", "")}
                        value={pr.value}
                        onChange={onChange}
                        onKeyDown={pr.onKeyDown ? pr.onKeyDown.bind(this) : this.onKeyDown.bind(this)}
                        onBlur={pr.onBlur ? pr.onBlur.bind(this) : null}
                        onFocus={pr.onFocus ? pr.onFocus.bind(this) : null}
                        readOnly={pr.readOnly}
                        maxLength={maxLength}
                    />
                    <Div
                        className="iconButtonContainer"
                        style={{...sty.iconButtonContainer, ...pr.iconButtonContainerStyle, ...StylesPlatform.cursorPointer}}
                        onClick={pr.onClickIcon.bind(this)}
                    >
                        <Icon
                            icon={pr.icon ? pr.icon : "arrow-right"}
                            size={18} style={sty.iconButtonStyle}
                        />
                    </Div>
                </Div>
            );
        }

        let input = (
            <input
                style={{...sty.input, ...inputColors, ...inputTypeStyle, ...pr.style, ...errorStyle}}
                id={Utils.get(pr, "id", "")}
                className={`inputElement ${Utils.get(pr, "className", "")}`}
                placeholder={Utils.get(pr, "placeholder", "")}
                type={Utils.get(pr, "type", "")}
                value={pr.value}
                onChange={onChange}
                onKeyDown={pr.onKeyDown ? pr.onKeyDown.bind(this) : null}
                onBlur={pr.onBlur ? pr.onBlur.bind(this) : null}
                onFocus={pr.onFocus ? pr.onFocus.bind(this) : null}
                readOnly={pr.readOnly}
                maxLength={pr.maxLength}
            />
        );

        if(pr.inputType === "placeholderLabel") {
            return (
                <Div className="placeholderLabelContainer" style={{...sty.placeholderLabelContainer, ...pr.placeholderLabelStyle}}>
                    <Div className="placeholderLabelInner" style={sty.placeholderLabelInner}>
                        {pr.value && pr.value.length > 0 ?
                            <Div className="placeholderLabelText" style={sty.placeholderLabelText}>
                                {pr.label ? pr.label : pr.placeholder}
                            </Div>
                            :
                            null
                        }

                        {input}
                    </Div>

                    {pr.importance ?
                        <Div className="importanceLabel" style={sty.importanceLabel}>
                            {pr.importance}
                        </Div>
                        :
                        null
                    }

                </Div>
            );
        }

        return input;
    }

    styles = {
        input: {
            paddingTop: 15,
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 14,
            borderRadius: 150,
            border: "none",
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            color: Colors.white,
            fontSize: 14,
            width: "100%",
            marginTop: 0,
            marginBottom: 20,
            boxSizing: "border-box",
            fontFamily: "Nyata",
            fontWeight: 300,
        },
        placeholderLabelContainer: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            borderRadius: 100,
            height: 52,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
        },
        placeholderLabelInner: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
        },
        importanceLabel: {
            fontFamily: "Nyata",
            fontWeight: 300,
            color: Colors.gray3,
            fontSize: 12,
            paddingLeft: 8,
        },
        placeholderLabelText: {
            fontFamily: "Nyata",
            fontWeight: 300,
            fontSize: 12,
            color: Colors.gray,
            marginBottom: 1,
            textAlign: "left",
        },
        searchInputContainer: {
            position: "relative",
            width: "100%",
        },
        iconStyle: {
            position: "absolute",
            top: 16,
            left: 20,
        },
        iconStyle: {
            position: "absolute",
            top: 16,
            left: 20,
        },
        searchInput: {
            paddingLeft: 50,
            color: "white",
        },
        iconButtonInputContainer: {
            position: "relative",
            boxSizing: "boder-box",
        },
        iconButtonContainer: {
            display: "flex",
            position: "absolute",
            top: 4,
            right: 4,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Colors.splashRed,
            width: 40,
            height: 40,
            borderRadius: 100,
        },
        iconButtonInput: {
            paddingRight: 50,
        },
        textInput: {
            paddingLeft: 40,
            backgroundColor: Colors.purpleInputBackground,
            color: "white",
        },
        errorStyle: {
            borderColor: Colors.redError,
        },
    };
}
