import React from 'react';

import Utils from '../modules/Utils';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';
import Validate from '../modules/Validate';
import Button from '../elements/Button';
import Image from '../elements/Image.js';
import TreeList from '../elements/TreeList.js';
import Div from './Div';

import DropboxIcon from "../assets/icons/dropbox-icon.png";
import OneDriveIcon from "../assets/icons/onedrive-icon.png";
import GoogleDriveIcon from "../assets/icons/google-drive-icon.png";

export default class FilePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            showUploadModal: false,
            uploadType: '',
        };

        this.GOOGLE_DRIVE_CLIENT_ID = '948862969246-ts60vus4s9ggu6psq3614mqfbgc0t34o.apps.googleusercontent.com';
        this.GOOGLE_DRIVE_API_KEY = 'AIzaSyDA3xsmx4KUJexIVpyoiA76lDcyj4NN03Q';
        this.GOOGLE_DRIVE_APP_ID = '948862969246';
        this.GOOGLE_DRIVE_SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

        this.ONE_DRIVE_CLIENT_ID = '9616b053-e2c7-453c-81fe-761a1a495cd0';
        this.ONE_DRIVE_REDIRECT_URO = 'https://readyai.ai/images/azure.html';
        this.ONE_DRIVE_SCOPES = 'files.readwrite';

        this.dropboxOptions = {
            // Required. Called when a user selects an item in the Chooser.
            success: (files) => {
                // void for now, set in componentDidMount
            },

            // Optional. Called when the user closes the dialog without selecting a file
            // and does not include any parameters.
            cancel: () => {
                // void for now, set in componentDidMount
            },

            // Optional. "preview" (default) is a preview link to the document for sharing,
            // "direct" is an expiring link to download the contents of the file. For more
            // information about link types, see Link types below.
            linkType: "direct", // or "preview"

            // Optional. A value of false (default) limits selection to a single file, while
            // true enables multiple file selection.
            multiselect: true, // or true

            // Optional. This is a list of file extensions. If specified, the user will
            // only be able to select files with these extensions. You may also specify
            // file types, such as "video" or "images" in the list. For more information,
            // see File types below. By default, all extensions are allowed.
            extensions: ['.pdf', '.doc', '.docx', '.jpg', '.png', '.mp4', '.mov', '.csv'],

            // Optional. A value of false (default) limits selection to files,
            // while true allows the user to select both folders and files.
            // You cannot specify `linkType: "direct"` when using `folderselect: true`.
            folderselect: false, // or true

            // Optional. A limit on the size of each file that may be selected, in bytes.
            // If specified, the user will only be able to select files with size
            // less than or equal to this limit.
            // For the purposes of this option, folders have size zero.
            sizeLimit: 1024 * 1024 * 1024, // or any positive number
        };
    }

    componentDidMount() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        this.fetchFiles();

        this.dropboxOptions.success = this.dropboxSuccessFiles.bind(this);
        this.dropboxOptions.cancel = this.dropboxCancelFiles.bind(this);

        this.googleDriveLoadApi();

        if (window.location.search.includes('code=')) {
            this.oneDriveHandleRedirect();
        }
    }

    oneDriveHandleRedirect = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            this.oneDriveExchangeAuthorizationCode(code);
        } else {
            console.error('Authorization code not found');
        }
    }

    oneDriveExchangeAuthorizationCode = (code) => {
        $.ajax({
            url: 'https://readyai.ai/images/token_exchange.html',
            method: 'POST',
            data: {
                code: code,
                redirectUri: this.redirectUri,
                clientId: this.clientId
            },
            success: (response) => {
                console.log('Token received:', response);
                // Handle the received token (store it, make API calls, etc.)
            },
            error: (error) => {
                console.error('Error exchanging authorization code:', error);
            }
        });
    }

    handleStartAction() {
        alert("Process");
    }

    googleDriveHandleAuthClick() {
        tokenClient.callback = async (response) => {
            if (response.error !== undefined) {
                throw (response);
            }
            accessToken = response.access_token;
            document.getElementById('signout_button').style.visibility = 'visible';
            document.getElementById('authorize_button').innerText = 'Refresh';
            await this.googleDriveCreatePicker();
        };

        if (accessToken === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken({ prompt: '' });
        }
    }

    async googleDrivePickerCallback(data) {
        console.log("pickerCallback >>>>>", data);
        if (data.action === google.picker.Action.PICKED) {
            let text = `Picker response: \n${JSON.stringify(data, null, 2)}\n`;
            const document = data[google.picker.Response.DOCUMENTS][0];
            const fileId = document[google.picker.Document.ID];
            console.log(fileId);
            const res = await gapi.client.drive.files.get({
                'fileId': fileId,
                'fields': '*',
            });
            text += `Drive API response for first document: \n${JSON.stringify(res.result, null, 2)}\n`;
            window.document.getElementById('content').innerText = text;
        }
    }

    googleDriveCreatePicker() {
        const view = new google.picker.View(google.picker.ViewId.DOCS);
        view.setMimeTypes('image/png,image/jpeg,image/jpg');
        const picker = new google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setDeveloperKey(this.GOOGLE_DRIVE_API_KEY)
            .setAppId(this.GOOGLE_DRIVE_APP_ID)
            .setOAuthToken(accessToken)
            .addView(view)
            .addView(new google.picker.DocsUploadView())
            .setCallback(this.googleDrivePickerCallback)
            .build();
        picker.setVisible(true);
    }

    googleDriveLoadApi() {
        const script = document.createElement('script');
        script.src = "https://apis.google.com/js/api.js";
        script.onload = () => {
            window.gapi.load('picker', () => {
                console.log('Google Picker API loaded');
            });
        };
        document.body.appendChild(script);
    }

    oneDriveLaunchPicker = async () => {
        if (window.OneDrive) {
            const odOptions = {
                clientId: this.clientId,
                action: "query",
                multiSelect: false,
                advanced: {
                    queryParameters: "select=id,name,folder",
                    filter: "folders",
                },
                success: (files) => {
                    console.log('Selected folder:', files);
                    // Process the selected folder information
                },
                cancel: () => {
                    console.log('File picker closed without selection');
                },
                error: (e) => {
                    console.error('Error picking file:', e);
                }
            };
            window.OneDrive.open(odOptions);
        } else {
            console.error('OneDrive.js is not loaded');
        }
    }

    googleDriveHandleSignoutClick() {
        if (accessToken) {
            accessToken = null;
            google.accounts.oauth2.revoke(accessToken);
            document.getElementById('content').innerText = '';
            document.getElementById('authorize_button').innerText = 'Authorize';
            document.getElementById('signout_button').style.visibility = 'hidden';
        }
    }


    async fetchFiles() {
        console.log("dbx >>>>>", dbx);
        console.log("dbx.filesListFolder >>>>>", dbx.filesListFolder);
        try {
            const res1 = await dbx.usersGetCurrentAccount();
            console.log("res1 >>>>>", res1);
            const res2 = await dbx.filesListFolder({ path: '/experian docs' });
            console.log("res2 >>>>>", res2);
            // this.setState({ files: res.result.entries });
        } catch (error) {
            console.error('Error fetching Dropbox folder: >>>>', error);
        }
    }

    async dropboxChoose() {
        const isAllowed = Dropbox.isBrowserSupported()
        Dropbox.choose(this.dropboxOptions)
        console.log("Dropbox.choose >>>>>", Dropbox.choose);
    }

    dropboxSuccessFiles(files) {
        console.log("successDropboxFiles >>>>>", files);
        this.setState({ files: files });
    }

    dropboxCancelFiles() {
        console.log("cancelDropboxFiles >>>>>");
    }

    handleAddUpload(addType) {
        this.setState({ showUploadModal: true, uploadType: addType });
    }

    renderDropboxFiles() {
        const st = this.state;
        const sty = this.styles;

        return (
            <div style={sty.dropboxDownloadLinksContainer}>
                {st.files.map((file, index) => (
                    <a key={index} href={file.link} target="_blank" style={{ color: "gray" }}>
                        {index + 1}: {file.name}
                    </a>
                ))}
            </div>
        );
    }

    renderTreeList() {
        let exampleFiles = [
            {
                "text": "Categorization",
                "expanded": true,
                "children": [
                    {
                        "text": "financial-statement-dis-20240629.html",
                        "expanded": false,
                        status: "Queued",
                        uploaded: "2024-10-21 1:14pm",
                        "children": []
                    },
                    {
                        "text": "financial-statement-dis-20240630.html",
                        "expanded": false,
                        status: "Processed 10-19-24",
                        uploaded: "2024-10-21 1:14pm",
                        "children": []
                    },
                    {
                        "text": "financial-statement-dis-20240530.html",
                        "expanded": false,
                        status: "Paused",
                        uploaded: "2024-10-21 1:14pm",
                        "children": []
                    },
                    {
                        "text": "financial-statement-dis-20240330.html",
                        "expanded": false,
                        status: "Failed",
                        uploaded: "2024-10-21 1:14pm",
                        "children": []
                    },
                ]
            },
            {
                "text": "Captioning",
                "expanded": true,
                "children": [
                    {
                        "text": "Utopian Interest Form Phone Numbers-20221021.csv",
                        "expanded": false,
                        "children": []
                    },
                    {
                        "text": "Utopian Interest Form Phone Numbers-20221023.csv",
                        "expanded": false,
                        "children": []
                    }
                ]
            }
        ];

        return (
            <TreeList
                data={exampleFiles}
                handleAddUpload={this.handleAddUpload.bind(this)}
            />
        );
    }

    render() {
        const pr = this.props;
        const st = this.state;
        const sty = this.styles;

        return (
            <Div className="filePickerComponent" style={sty.filePickerComponent}>
                <Div style={{ display: "flex", flexDirection: "row" }}>
                    <Div style={this.styles.treeContainer}>
                        <Div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "0px 10px", justifyContent: "flex-end", }}>
                            Action:
                            <select>
                                <option>___ Choose Action ___</option>
                                <option>Select All</option>
                                <option>Pause</option>
                                <option>Resume</option>
                                <option>Delete</option>

                            </select>
                            <Button onClick={this.handleStartAction.bind(this)}>Start</Button>
                        </Div>
                        <>
                            {this.renderTreeList()}
                        </>
                    </Div>
                    <Div style={{ flex: 1, }}>
                        {this.state.showUploadModal ?
                            <Div style={sty.dropboxButtonContainer}>
                                <Div style={{ fontSize: 18, fontWeight: 400, textAlign: "center" }}>
                                    Upload Files for {this.state.uploadType} from
                                </Div>

                                <Button
                                    onClick={() => this.dropboxChoose()}
                                    size="small"
                                    color="white"
                                    style={{ width: 200 }}
                                >
                                    Local Drive
                                </Button>
                                <Button
                                    onClick={() => this.dropboxChoose()}
                                    size="small"
                                    color="white"
                                    style={{ width: 200 }}
                                >
                                    Dropbox <Image src={DropboxIcon} style={{ width: 20, marginBottom: 6 }} />
                                </Button>
                                <Button
                                    onClick={() => this.oneDriveLaunchPicker()}
                                    size="small"
                                    color="white"
                                    style={{ width: 200 }}
                                >
                                    OneDrive <Image src={OneDriveIcon} style={{ width: 20, marginBottom: 6 }} />
                                </Button>
                                <Button
                                    onClick={() => this.googleDriveHandleAuthClick()}
                                    size="small"
                                    color="white"
                                    style={{ width: 200 }}
                                >
                                    Google Drive <Image src={GoogleDriveIcon} style={{ width: 20, marginBottom: 6 }} />
                                </Button>

                                {this.renderDropboxFiles()}


                                <pre id="content" style={{ whiteSpace: "pre-wrap" }}></pre>

                                <button id="authorize_button" onClick={() => this.googleDriveHandleAuthClick()}>Authorize</button>
                                <button id="signout_button" onClick={() => this.googleDriveHandleSignoutClick()}>Sign Out</button>
                            </Div>
                            :
                            null
                        }

                    </Div>
                </Div>
            </Div>
        );
    }

    styles = {
        filePickerComponent: {
            padding: 20,
            color: "white",
        },
        dropboxButtonContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            padding: 20,
            border: "1px dashed rgba(255, 255, 255, 0.5)",
            borderRadius: 10,
            width: "fit-content",
            marginLeft: 40,
        },
        dropboxDownloadLinksContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: 16,
            gap: 10,
            padding: 20,
            width: "fit-content",
            margin: "0 auto",
            fontWeight: 400,
            color: "lightgray",
        },
        treeContainer: {
            fontWeight: 300,
            borderRadius: 12,
            padding: 10,
            flex: 2,
            width: "100%",
            height: 400,
            background: "#F5F8FB",
            color: "black"
        },
    };
}
